import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className='d-flex justify-content-center align-items-end border-bottom'>
      <div className="w-25"></div>
      <div className='w-50'><h1 className='text-center'><Link to="/">RGV News</Link></h1></div>
      <div className="pb-3 w-25">
        
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
